import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { GeneralAPIs } from '../../network/apis/GeneralAPIs';
import {
  AppSearch,
  Customer,
  Project,
  Site,
  Task,
} from '../../network/models/AppModels';
import {
  APP_SEARCH_QUERY_KEY,
  COLOR_LIGHT,
  CUSTOMERS_PATH,
  ERROR_SNACKBAR_OPTIONS,
  PROJECTS_PATH,
  SITES_PATH,
  TASKS_PATH,
} from '../../utils/Constants';
import HelperFunctions from '../../utils/HelperFunctions';

function HeaderAutoCompleteSearch() {
  const [searchValue, setSearchValue] = useState<any>(null);
  const generalApi = useMemo(() => new GeneralAPIs(), []);
  const [appSearchsError, setAppSearchsError] = useState<any>(null);
  const [appSearchs, setAppSearchs] = useState<AppSearch[] | any>([]);
  const [queryString, setQueryString] = useState<any>('');
  const { enqueueSnackbar } = useSnackbar();

  const {
    isFetching: isFetchingAppSearches,
    data,
    refetch,
    error,
  } = useQuery({
    queryKey: [APP_SEARCH_QUERY_KEY],
    queryFn: () => generalApi.appSearch(`q=${queryString}`),
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar(String(error), ERROR_SNACKBAR_OPTIONS);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const appSearchData: AppSearch = data as AppSearch;
      setAppSearchs([
        ...appSearchData.customers.map((customer: Customer) => ({
          type: 'Customers',
          ...customer,
        })),
        ...appSearchData.projects.map((project: Project) => ({
          type: 'Projects',
          ...project,
        })),
        ...appSearchData.tasks.map((task: Task) => ({
          type: 'Tasks',
          ...task,
        })),
        ...appSearchData.sites.map((site: Site) => ({
          type: 'Sites',
          ...site,
        })),
      ]);
    }
  }, [data]);

  useEffect(() => {
    const delayTimer = setTimeout(() => {
      refetch();
    }, 1000);

    return () => clearTimeout(delayTimer);
  }, [queryString]);

  const onChangeHandle = (value: string) => {
    setQueryString(value);
  };

  const getProjectResultLink = (project: Project) => {
    return `${PROJECTS_PATH}?${HelperFunctions.objectToCommaQueryString({ p_name: project?.name || '' })}`;
  };

  const getTaskResultLink = (task: Task | any) => {
    const paramName = task?.name ? 't_name' : 't_id';
    let paramValue = task?.name ? task?.name : task?.id ? task?.id : '';

    let project_name = task?.project_name;
    if (task?.parent_task) {
      paramValue = task?.parent_task?.name
        ? task?.parent_task?.name
        : task?.parent_task?.id
          ? task?.parent_task?.id
          : '';
      project_name = task?.parent_task?.project_name;
    }
    return `${TASKS_PATH}?${HelperFunctions.objectToCommaQueryString({
      t_name: task?.name ?? '',
      t_id: task?.id ?? '',
      p_name: project_name || '',
      ...(task?.parent_task ? { t_expanded: task?.parent_task?.id } : {}),
    })}`;
  };

  const handleNavigate = (selectedItem: any) => {
    switch (selectedItem?.type) {
      case 'Customers':
        window.location.href = `${CUSTOMERS_PATH}?${HelperFunctions.objectToCommaQueryString({ c_name: selectedItem?.name || '' })}`;

        setSearchValue(null);
        break;
      case 'Projects':
        window.location.href = getProjectResultLink(selectedItem);
        setSearchValue(null);
        break;
      case 'Tasks':
        window.location.href = getTaskResultLink(selectedItem);
        setSearchValue(null);
        break;
      case 'Sites':
        window.location.href = `${SITES_PATH}?${HelperFunctions.objectToCommaQueryString({ s_name: selectedItem?.name || '' })}`;
        setSearchValue(null);
        break;

      default:
        break;
    }
  };
  return (
    <FormControl fullWidth variant="standard" error={appSearchsError}>
      <Autocomplete
        id="header-search"
        options={appSearchs}
        groupBy={(option) => option.type}
        getOptionLabel={(option) => option.name}
        sx={{ width: '350px' }}
        value={searchValue}
        onChange={(event: any, selectedItem: any | null) =>
          handleNavigate(selectedItem)
        }
        renderOption={(props, option, index) => {
          const key = `listItem-${index}-${option.id}`;
          return (
            <li {...props} key={key}>
              {option?.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Quick Search"
            value={queryString}
            onChange={(event: any) => {
              if (event.target.value !== '' || event.target.value !== null) {
                onChangeHandle(event.target.value);
              }
            }}
            InputProps={{
              ...params.InputProps,
              sx: { background: COLOR_LIGHT },
              endAdornment: (
                <Fragment>
                  {isFetchingAppSearches && (
                    <CircularProgress color="inherit" size={14} />
                  )}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
            InputLabelProps={{
              sx: { lineHeight: 'inherit' },
            }}
          />
        )}
      />
    </FormControl>
  );
}

export default HeaderAutoCompleteSearch;
