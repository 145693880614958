import {
  URL_TASKS,
  URL_TASKS_ADD_TO_WATCH_LIST,
  URL_TASKS_BULK_UPDATE,
  URL_TASKS_DELETE_WATCH_LIST,
  URL_TASKS_MY_WATCH_LIST,
  URL_TASKS_READ_WATCH_LIST_ALERT,
  URL_TASKS_SEARCH,
  URL_TASKS_SUMMARY,
  URL_TASKS_SUMMARY_STATS,
} from '../../utils/Constants';
import { PrivateApi } from '../PrivateApi';
import {
  Comment,
  Task,
  TaskTeamSummary,
  TaskWatchList,
} from '../models/AppModels';
import HelperFunctions from '../../utils/HelperFunctions';

export class TaskAPIs extends PrivateApi {
  getTasks(params: any) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_TASKS}${HelperFunctions.generateUrlParams(params)}`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          const tasks: Task[] = response?.results?.map(
            (item: Task) => new Task(item)
          );
          if (params?.tasksV2) {
            localStorage['groupedTasksSize'] = response?.count;
          } else {
            localStorage['tasksSize'] = response?.count;
          }

          resolve({
            data: tasks,
            page:
              tasks?.length > 0 && response?.next
                ? params?.page + 1
                : undefined,
          });
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  searchTasks(params: any) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_TASKS_SEARCH}${HelperFunctions.generateUrlParams(params)}`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          resolve(response);
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  getTasksSummary(params: any) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_TASKS_SUMMARY}${HelperFunctions.generateUrlParams(params)}`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          const tasksSummary: TaskTeamSummary = response;
          resolve(tasksSummary);
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  getTasksSummaryStats(params: any) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_TASKS_SUMMARY_STATS}${HelperFunctions.generateUrlParams(params)}`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          const tasksSummaryStats: TaskTeamSummary = response;
          resolve(tasksSummaryStats);
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  getTaskById(id: string) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_TASKS}${id}/?show_subtasks=true`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          resolve(new Task(response));
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }
  getTaskSubTasks(taskId: string) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_TASKS}${taskId}/sub-tasks/`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          const subTasks: Task[] = response?.map((t: Task) => new Task(t));
          resolve(subTasks);
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  getTaskComments(taskId: string) {
    return new Promise((resolve, reject) => {
      this.get(
        `${URL_TASKS}${taskId}/comments/`,
        this.getAuthenticatedHeaders()
      )
        .then((response: any) => {
          const comments: Comment[] = response?.map(
            (c: Comment) => new Comment(c)
          );
          resolve(comments);
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  addTask(
    task: Task,
    callback: (is_successful: boolean, model_or_error: Task | string) => void
  ) {
    this.post(`${URL_TASKS}`, task, this.getAuthenticatedHeaders())
      .then((response: any) => {
        const task: Task = new Task(response);
        callback(true, task);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  updateTask(
    task: Task,
    callback: (is_successful: boolean, model_or_error: Task | string) => void
  ) {
    this.patch(`${URL_TASKS}${task?.id}/`, task, this.getAuthenticatedHeaders())
      .then((response: any) => {
        const task: Task = new Task(response);
        callback(true, task);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  updateBulkTask(
    body: { fields: any; ids: string[] },
    callback: (is_successful: boolean, model_or_error: Task[] | string) => void
  ) {
    this.post(`${URL_TASKS_BULK_UPDATE}`, body, this.getAuthenticatedHeaders())
      .then((response: any) => {
        const tasks: Task[] = response?.map((t: Task) => new Task(t));
        callback(true, tasks);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  addToWatchList(
    body: { taskIds: string[] },
    callback: (is_successful: boolean, model_or_error: string) => void
  ) {
    this.post(
      `${URL_TASKS_ADD_TO_WATCH_LIST}`,
      body,
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        callback(true, response);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  readWatchListAlert(
    body: { pks: string[] },
    callback: (is_successful: boolean, model_or_error: string) => void
  ) {
    this.post(
      `${URL_TASKS_READ_WATCH_LIST_ALERT}`,
      body,
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        callback(true, response);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  getUserTasksWatchList() {
    return new Promise((resolve, reject) => {
      this.get(`${URL_TASKS_MY_WATCH_LIST}`, this.getAuthenticatedHeaders())
        .then((response: any) => {
          const taskWatchLists: TaskWatchList[] = response?.map(
            (item: TaskWatchList) => new TaskWatchList(item)
          );
          resolve(taskWatchLists);
        })
        .catch((error: any) => {
          reject(this.extractResponseError(error));
        });
    });
  }

  deleteTaskWatchListById(
    ids: string[],
    callback: (is_successful: boolean, model_or_error: any) => void
  ) {
    this.delete(
      `${URL_TASKS_DELETE_WATCH_LIST}?pks=${ids.join(',')}`,
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        callback(true, response);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  copyTasks(
    ids: string[],
    callback: (is_successful: boolean, model_or_error: any) => void
  ) {
    this.post(
      `${URL_TASKS}copy/`,
      { taskIds: ids },
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        const tasks: Task[] = response?.map((t: Task) => new Task(t));
        callback(true, tasks);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }

  deleteTaskById(
    ids: string[],
    callback: (is_successful: boolean, model_or_error: any) => void
  ) {
    this.delete(
      `${URL_TASKS}delete-many/?pks=${ids.join(',')}`,
      this.getAuthenticatedHeaders()
    )
      .then((response: any) => {
        callback(true, response);
      })
      .catch((error: any) => {
        callback(false, this.extractResponseError(error));
      });
  }
}
