import { lazy } from 'react';
import Loadable from '../components/Loadable';
import MinimalLayout from '../layouts/MinimalLayout';
import { LOGIN_PATH, NOT_ALLOWED_PATH, ROOT_PATH } from '../utils/Constants';

const AuthLogin = Loadable(lazy(() => import('../pages/account/Login')));

const AuthRoutes = {
  path: ROOT_PATH,
  element: <MinimalLayout />,
  children: [
    {
      path: LOGIN_PATH,
      element: <AuthLogin />,
    },
    {
      path: NOT_ALLOWED_PATH,
      element: (
        <div
          style={{
            color: '#fff',
            width: '100%',
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          Not allowed
        </div>
      ),
    },
  ],
};

export default AuthRoutes;
